@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
    font-family: "heebo", sans-serif;
    /* font-family: "DM Sans", sans-serif; */
}


/* style for date picker */

.chakra-date-picker .react-date-picker__wrapper {
    border-radius: 6px;
    justify-content: center;
    display: flex;
    gap: 4px;
    align-items: center;
}

.chakra-date-picker .react-date-picker__inputGroup__input {
    text-align: center;
}

.chakra-date-picker .react-date-picker__inputGroup {
    font-size: 16px;
    /* width: 100%;
    min-width: 200px; */
}


/* Global styles or a CSS module */

.custom-calendar .react-calendar__tile {
    position: relative;
}

.custom-calendar .react-calendar__tile abbr {
    display: none;
    /* Hide default date number */
}

option {
    color: black;
}